/* eslint-disable */
export default {
  pages: {
    key: "title",
    data: [
      {title: 'Home',   url: '/',      icon: 'HomeIcon', is_bookmarked: false},
      {title: 'Page 2', url: '/page2', icon: 'FileIcon', is_bookmarked: false},
      {title: 'Page 3', url: '/page3', icon: 'FileIcon', is_bookmarked: false},
      {title: 'Page 4', url: '/page4', icon: 'FileIcon', is_bookmarked: false},
      {title: 'Page 5', url: '/page5', icon: 'FileIcon', is_bookmarked: false},
      {title: 'Page 6', url: '/page6', icon: 'FileIcon', is_bookmarked: false},
      {title: 'Page 7', url: '/page7', icon: 'FileIcon', is_bookmarked: false},
      {title: 'Page 8', url: '/page8', icon: 'FileIcon', is_bookmarked: false},
    ]
  }
}
/* eslint-enable */
