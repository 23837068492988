export default {
  users: {
    store: 'شخص جدید',
    list: 'اشخاص'
  },
  sale: {
    store: 'فروش جدید',
    list: 'فروش'
  },
  return_sale: {
    store: 'مرجوع فروش',
    list: 'مرجوع فروش'
  },
  purchase: {
    store: 'خرید جدید',
    list: 'خرید'
  },
  return_purchase: {
    store: 'مرجوع خرید',
    list: 'مرجوع خرید'
  },
  product: {
    store: 'کالای جدید',
    list: 'کالا'
  },
  role: {
    list: 'گروه کاربری'
  },
  receive: {
    store: 'دریافت جدید',
    list: 'دریافت'
  },
  payment: {
    store: 'پرداخت جدید',
    list: 'پرداخت'
  },
  internal_fund: {
    store: 'انتقالی جدید',
    list: 'انتقالی'
  },
  cost: {
    store: 'هزینه جدید',
    list: 'هزینه'
  },
  bank: {
    store: 'بانک جدید',
    list: 'بانک'
  },
  cash: {
    store: 'صندوق جدید',
    list: 'صندوق'
  },
  payment_gateway: {
    store: 'درگاه پرداخت جدید',
    list: 'درگاه پرداخت'
  },
  service: {
    store: 'خدمت جدید',
    list: 'خدمت'
  },
  storeroom_receipt: {
    store: 'حواله جدید',
    list: 'حواله انبار'
  },
  storeroom: {
    store: 'انبار جدید',
    list: 'انبار'
  },
  storeroom_deficit: {
    store: 'کسورات و اضافات جدید',
    list: 'کسورات و اضافات'
  },
  storeroom_conversion: {
    store: 'تبدیلات جدید',
    list: 'تبدیلات'
  }
}
