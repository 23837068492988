export default {
  title: 'Warehousing',

  stores: {
    title: 'Warehouses',

    labels: {
      name: 'Name',
      code: 'Code',
      delete: 'Delete'
    },

    list: {
      title: 'Warehouses',

      table: {
        header: {
          rowNumber: 'Row',
          code: 'Code',
          name: 'Title',
          balance: 'Inventory'
        }
      }
    },

    insert: {
      title: 'Add Warehouse',

      notifications: {
        insert: {
          success: 'New warehouse information successfully registered',
          error: 'Add new warehouse failed'
        }
      }
    },

    edit: {
      title: 'Edit Warehouse',

      notifications: {
        edit: {
          success: 'Warehouse information successfully edited',
          error: 'Editing warehouse information failed'
        }
      }
    },

    store: {
      title: 'Warehouse',

      importantData: {
        code: 'Warehouse ID'
      },

      actions: {
        storeTransactions: 'Transactions',
        storeTurnover: 'Turnover',
        storeInventory: 'Inventory',
        storeInventoryValuation: 'Review the amount of available products',
        logs: 'Activity logs'
      }
    },

    trash: {
      title: 'Warehouse',

      table: {
        header: {
          rowNumber: 'Row',
          code: 'Code',
          name: 'Title',
          delete: 'Delete',
          restore: 'Restore'
        }
      },

      confirmations: {
        delete: {
          title: 'Warehouse deletion confirmation',
          body: 'Are you sure you want to delete {name} permanently?'
        }
      },

      notifications: {
        delete: {
          success: 'Warehouse successfully deleted',
          error: 'Deleting warehouse information failed'
        },
        restore: {
          success: 'Warehouse successfully returned',
          error: 'Warehouse restored failed'
        }
      }
    },

    delete: {
      confirmations: {
        delete: {
          title: 'Warehouse deletion confirmation',
          body: 'Are you sure you want to temporarily delete warehouse {name}?'
        }
      },

      notifications: {
        delete: {
          success: 'Warehouse information successfully deleted',
          error: 'Deleting warehouse information failed'
        }
      }
    },

    validators: {
      name: 'Warehouse name not entered correctly',
      nameCantEmpty: 'Warehouse name could not be empty',
      code: 'Warehouse code not entered correctly',
      codeCantEmpty: 'Warehouse code could not be empty'
    }
  },

  turnover: {
    title: 'Turnover',
    dynamicTitle: '{name} Turnover',

    table: {
      header: {
        row: 'Row',
        docNumber: 'Document number',
        name: 'Title',
        image: 'Photo',
        date: 'Date',
        barcode: 'Barcode',
        code: 'Code',
        category: 'Category',
        variant: 'Variable',
        storeroom: 'Storeroom',
        quantity: 'Number',
        unitPrice: 'Unit amount',
        description: 'Documents',
        price: 'Amount',
        balance: 'Inventory',
        status: 'Status'
      }
    },

    statusMapper: {
      sent: 'Posted',
      onWay: 'On way',
      received: 'Receipted',
      waitingToSend: 'Waiting to send'
    }
  },

  transactions: {
    title: 'Transaction',
    dynamicTitle: '{name} Transaction',

    table: {
      header: {
        docNumber: 'Document number',
        name: 'Title',
        image: 'Photo',
        date: 'Date',
        barcode: 'Barcode',
        code: 'Code',
        category: 'Category',
        variant: 'Variable',
        quantity: 'Number',
        unitPrice: 'Unit amount',
        description: 'Documents',
        price: 'Amount',
        balance: 'Inventory',
        status: 'Status'
      }
    },

    statusMapper: {
      sent: 'Posted',
      onWay: 'On way',
      received: 'Receipted',
      waitingToSend: 'Waiting to send'
    }
  },

  logs: {
    title: 'Warehouse logs',
  },

  inventory: {
    title: 'Inventory',
    dynamicTitle: '{name} Inventory',

    tabs: {
      inventory: 'Inventory',
      turnover: 'Product warehouse turnover'
    },

    table: {
      header: {
        rowNumber: 'Row',
        image: 'Photo',
        barcode: 'Barcode',
        code: 'Code',
        category: 'Category',
        name: 'Title',
        variant: 'Variable',
        openingInventory: 'First Course',
        received: 'Reached warehouse',
        minInventory: 'Minimum Inventory',
        totalInventory: 'Total inventory',
        sent: 'Posted',
        onWay: 'On the way',
        waitingToSend: 'Waiting to send',
        saleInventory: 'Inventory for sale'
      }
    },

    notifications: {
      export: {
        success: 'The request to get the output of browsing some warehouses has been successfully completed, please wait.',
        error: 'The request to receive the output of a number of warehouses was accompanied by an error.'
      }
    }
  },

  inventoryValuation: {
    title: 'Browse Warehouse Amount',
    dynamicTitle: 'Browse Warehouse Amount {name}',

    table: {
      header: {
        rowNumber: 'Row',
        image: 'Photo',
        barcode: 'Barcode',
        code: 'Code',
        category: 'Category',
        name: 'Title',
        variant: 'Variable',
        openingInventory: 'First Course',
        received: 'Reached warehouse',
        minInventory: 'Minimum Inventory',
        totalInventory: 'Total inventory',
        sent: 'Posted',
        onWay: 'On the way',
        waitingToSend: 'Waiting to send',
        saleInventory: 'Inventory for sale'
      }
    }
  },

  receipts: {
    title: 'Delivery Note',

    list: {
      title: 'Delivery Note',

      storeName: '{name} warehouse',

      table: {
        header: {
          id: 'Reference',
          creator: 'Creator',
          accountSide: 'Account side',
          invoiceId: 'Invoice ID',
          type: 'Type',
          approved: 'Status',
          createdAt: 'Registration Date'
        },

        filters: {
          type: {
            all: 'All',
            receive: 'Pull in',
            transfer: 'Transfer',
            deficit: 'Deficit',
            surplus: 'Surplus',
            conversion: 'Conversion',
            exit: 'Pull out'
          },

          approved: {
            all: 'All',
            confirm: 'Confirmed',
            pending: 'pending'
          }
        }
      },

      receiptTypes: {
        receive: 'Pull in',
        transfer: 'Transfer',
        deficit: 'Deficit',
        surplus: 'Surplus',
        conversion: 'Conversion',
        exit: 'Pull out'
      },

      statusTypes: {
        confirm: 'Confirmed',
        pending: 'pending'
      }
    },

    insert: {
      title: 'Add Delivery Note',

      labels: {
        type: 'Delivery note type',
        store: 'Warehouse',
        originStore: 'Source warehouse',
        destinationStore: 'Destination warehouse',
        registryDate: 'Registration Date',
        insertProduct: 'Add new product',
        first: 'first',
        second: 'second'
      },

      table: {
        header: {
          barcode: 'Barcode',
          name: 'Product',
          variant: 'Variable',
          count: 'Count',
          unitPrice: 'Unit amount',
          totalPrice: 'Total amount',
          storeroom: 'Source warehouse',
          destinationStoreroom: 'Destination Warehouse',
          delete: 'Delete'
        }
      },

      receiptTypes: {
        receive: 'Pull in',
        transfer: 'Transfer',
        exit: 'Pull out'
      }
    },

    edit: {
      title: 'Edit Delivery Note'
    },

    receipt: {
      title: 'Delivery Note',
      dynamicTitle: '{name} Delivery Note',

      labels: {
        user: 'Account side',
        supplier: 'Supplier',
        orderId: 'Order No',
        price: 'Price',
        registryDate: 'Registration Date',
        orderDate: 'Order Date',
        phoneNumber: 'Phone Number',
        type: 'Type',
        status: 'Status',
        note: 'Note',
        signature: 'Signature',
        receiverName: 'Receiver Name',
        senderName: 'Sender Name',
        print: 'Print',
        back: 'Back'
      },

      prompts: {
        noteDialog: {
          title: 'Customer Invoice Note'
        }
      },

      table: {
        header: {
          rowNumber: 'Row',
          image: 'Photo',
          code: 'Barcode',
          name: 'Description',
          store: 'Warehouse',
          originStore: 'Source warehouse',
          destinationStore: 'Destination warehouse',
          count: 'Count',
          unitPrice: 'Unit amount',
          totalPrice: 'Total amount'
        }
      },

      validators: {
        count: 'The number {name} contradicts the number of this product in the invoice',
        scan: '{name} not scanned'
      },

      confirmation: {
        confirm: {
          title: 'Reference confirmation {name}',
          body: 'Are you sure about {name} the above delivery note?'
        },
        delete: {
          title: 'Delete confirmation {name} receipt',
          body: 'Are you sure about delete {name} receipt?'
        }
      },

      receiptTypes: {
        openingInventory: 'Opening inventory',
        receive: 'Pull in',
        transfer: 'Transfer',
        deficit: 'Deficit',
        surplus: 'Surplus',
        conversion: 'Conversion',
        exit: 'Pull out'
      },

      receiptActions: {
        receive: 'Pull in',
        transfer: 'Transfer',
        exit: 'Pull out'
      },

      status: {
        confirm: 'Confirmed',
        notConfirm: 'not approved'
      },

      postalLabel: {
        title: 'Postal label',
        dynamicTitle: 'Postal label sale invoice No. {number}',

        theme1: {
          orderNumber: 'Order No:',
          shipDate: 'Ship Date: ',
          customer: 'CUSTOMER: ',
          shipTo: 'SHIP TO: ',
          shipFrom: 'SHIP FROM: ',
          receiverInfo: 'SHIP TO INFO',
          senderInfo: 'SHIP FROM INFO',
          receiverName: 'Ship to name',
          senderName: 'Ship from name',
          address: 'Address',
          phoneNumber: 'Phone number',
          poBox: 'PO BOX ',
          callBeforeSend: 'Please call before sending',
          note: 'Note: ',
          insurancePrice: 'Insurance price'
        },

        theme2: {
          orderNumber: 'Order No: ',
          shipDate: 'Ship Date: ',
          customer: 'CUSTOMER: ',
          shipTo: 'SHIP TO: ',
          note: 'Note: ',
          shipFrom: 'SHIP FROM: ',
          poBox: 'PO BOX ',
          logesticSupport: 'Logistics Support: ',
          customerService: 'Customer Service: '
        },

        actions: {
          print: 'Print',
          back: 'Back'
        }
      },

      logs: {
        title: 'Delivery Note Logs',
      },
    },

    receiptConfirm: {
      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Product',
          count: 'Count',
          delete: 'Delete'
        }
      },

      validators: {
        notFound: 'No product found with this barcode',
        notExist: 'There is no product with this barcode in the invoice',
        noProductChoose: 'No products selected'
      },

      notifications: {
        insertProduct: {
          success: 'Number of items added'
        },

        dynamicInsertProduct: {
          success: 'Number { name } added'
        }
      }
    },

    validators: {
      store: 'Warehouse not selected!',
      originStore: 'Source warehouse not selected',
      destinationStore: 'Destination warehouse not selected',
      valuesExist: 'This value is in the list',
      productExist: '{name} is in list',
      noProductChoose: 'No product selected',
      barcode: 'No product found with this barcode',
      price: 'The amount of goods has not been imported!',
      productCount: 'Inventory is not enough!',
      dynamicProductCount: '{name} inventory is not enough',
      quantity: 'The number of entered products is not valid!'
    },

    notifications: {
      insert: {
        success: 'Delivery note registered successfully',
        error: 'Delivery note registration was accompanied by an error!'
      },

      delete: {
        success: 'Delivery note removed successfully',
        error: 'Delete delivery note was accompanied by an error!'
      },

      confirm: {
        success: 'Warehouse transfer confirmed successfully',
        error: 'Confirmation of warehouse transfer was accompanied by an error'
      }
    }
  },

  deficit: {
    title: 'Deficit',

    table: {
      header: {
        row: 'Row',
        date: 'Date',
        description: 'Description',
        type: 'Type',
        status: 'Status',
        creator: 'Creator',
        barcode: 'Barcode',
        product: 'Product',
        warehouse: 'Warehouse',
        count: 'QTY',
        delete: 'Delete',
        restore: 'Restore'
      }
    },

    labels: {
      allProduct: 'All products',
      warehouse: 'Warehouse',
      id: 'ID',
      registryDate: 'Registry date',
      type: 'Type',
      status: 'Status'
    },

    notifications: {
      isExists: {
        message: 'This value is in the list',
        dynamicMessage: 'There are {name} in the list'
      },

      increaseCount: {
        message: 'Number of items added',
        dynamicMessage: 'Number of { names } added'
      },

      productNotFount: 'No product found with this barcode',
      quantity: 'Inventory is not enough!',
      dynamicQuantity: 'There is not enough stock of {name}!',

      insert: {
        deficit: {
          success: 'Warehouse deficit registered successfully',
          error: 'Warehouse deficit registration was incorrect!'
        },
        surplus: {
          success: 'Warehouse surplus registered successfully',
          error: 'Warehouse surplus registration was incorrect!'
        }
      },

      edit: {
        deficit: {
          success: 'Warehouse deficit edited successfully',
          error: 'Warehouse deficit editing encountered an error!'
        },
        surplus: {
          success: 'Warehouse surplus edited successfully',
          error: 'Warehouse surplus editing encountered an error!'
        }
      },

      delete: {
        deficit: {
          success: 'Warehouse deficit successfully deleted',
          error: 'Deleting the warehouse deficit was accompanied by an error'
        },
        surplus: {
          success: 'Warehouse surplus successfully deleted',
          error: 'Deleting the warehouse surplus was accompanied by an error'
        }
      },

      restore: {
        deficit: {
          success: 'Warehouse deficit successfully restored',
          error: 'Restore warehouse deficit was accompanied by an error'
        },
        surplus: {
          success: 'Warehouse surplus successfully restored',
          error: 'Restore warehouse surplus was accompanied by an error'
        }
      },

      parseError: {
        storeroom: 'Warehouse not selected!',
        stocks: 'No items selected!',
        description: 'Description field is empty!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'The number of selected products is not valid!',
        noProductEnter: 'No Product Selected!'
      }
    },

    confirmations: {
      delete: {
        deficit: {
          title: 'Confirmation of deletion of warehouse deficit',
          body: 'If the warehouse deficit is deleted, all the documents and delivery note of the warehouse related to the invoice will be deleted. Are you sure? '
        },
        surplus: {
          title: 'Confirmation of deletion of warehouse surplus',
          body: 'If the warehouse surplus is deleted, all the documents and delivery note of the warehouse related to the invoice will be deleted. Are you sure? '
        }
      },
      forceDelete: {
        title: 'Confirmation of deletion of invoice',
        body: 'If the invoice is deleted, the information of invoice deleted and cant restore it. Are you sure? '
      },
      forceUpdate: {
        deficit: {
          title: 'Warehouse deficit edit confirmation',
          body: 'By edit warehouse deficit, All documents related to this invoice change. Are you sure of confirming edit warehouse deficit?'
        },
        surplus: {
          title: 'Warehouse surplus edit confirmation',
          body: 'By edit warehouse surplus, All documents related to this invoice change. Are you sure of confirming edit warehouse surplus?'
        }
      }
    },

    types: {
      deficit: 'Deficit',
      surplus: 'Surplus'
    },

    statusTypes: {
      draft: 'Draft',
      confirmed: 'Approved'
    },

    list: {
      title: 'Warehouse Deficit'
    },

    trash: {
      title: 'Removed Warehouse Deficit List'
    },

    insert: {
      title: 'Insert Warehouse Deficit',
      dynamicTitle: {
        deficit: 'Insert Warehouse Deficit',
        surplus: 'Insert Warehouse Surplus'
      }
    },

    profile: {
      title: 'Warehouse Deficit Profile',
      dynamicTitle: {
        deficit: 'Warehouse Deficit Profile',
        surplus: 'Warehouse Surplus Profile'
      }
    },

    edit: {
      title: 'Edit Warehouse Deficit',
      dynamicTitle: {
        deficit: 'Edit Warehouse Deficit',
        surplus: 'Edit Warehouse Surplus'
      }
    },

    logs: {
      title: 'Invoice Logs',
    },
  },

  conversion: {
    title: 'Conversion',

    table: {
      header: {
        row: 'Row',
        date: 'Date',
        description: 'Description',
        invoiceId: 'Invoice No.',
        status: 'Status',
        creator: 'Creator',
        sourceBarcode: 'Source barcode',
        sourceName: 'Source name',
        sourceCount: 'Source QTY',
        destinationBarcode: 'Destination barcode',
        destinationName: 'Destination name',
        rate: 'Exchange rate',
        warehouse: 'Warehouse',
        count: 'QTY',
        delete: 'Delete',
        restore: 'Restore'
      }
    },

    labels: {
      allProduct: 'All products',
      warehouse: 'Warehouse',
      id: 'ID',
      registryDate: 'Registry date',
      type: 'Type',
      status: 'Status'
    },

    notifications: {
      isExists: {
        message: 'This value is in the list',
        dynamicMessage: 'There are {name} in the list'
      },

      increaseCount: {
        message: 'Number of items added',
        dynamicMessage: 'Number of { names } added'
      },

      productNotFount: 'No product found with this barcode',
      quantity: 'Inventory is not enough!',
      dynamicQuantity: 'There is not enough stock of {name}!',

      insert: {
        success: 'Warehouse conversion registered successfully',
        error: 'Warehouse conversion registration was incorrect!'
      },

      edit: {
        success: 'Warehouse conversion edited successfully',
        error: 'Warehouse conversion editing encountered an error!'
      },

      delete: {
        success: 'Warehouse conversion successfully deleted',
        error: 'Deleting the warehouse conversion was accompanied by an error'
      },

      restore: {
        success: 'Warehouse conversion successfully restored',
        error: 'Restore warehouse conversion was accompanied by an error'
      },

      parseError: {
        storeroom: 'Warehouse not selected!',
        stocks: 'No items selected!',
        description: 'Description field is empty!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'The number of selected products is not valid!',
        noProductEnter: 'No Product Selected!'
      }
    },

    confirmations: {
      delete: {
        title: 'Confirmation of deletion of warehouse conversion',
        body: 'If the warehouse conversion is deleted, all the documents and delivery note of the warehouse related to the invoice will be deleted. Are you sure? '
      },
      forceDelete: {
        title: 'Confirmation of deletion of invoice',
        body: 'If the invoice is deleted, the information of invoice deleted and cant restore it. Are you sure? '
      },
      forceUpdate: {
        title: 'Warehouse conversion edit confirmation',
        body: 'By edit warehouse conversion, All documents related to this invoice change. Are you sure of confirming edit warehouse conversion?'
      }
    },

    types: {
      deficit: 'Deficit',
      surplus: 'Surplus'
    },

    statusTypes: {
      draft: 'Draft',
      confirmed: 'Approved'
    },

    list: {
      title: 'Warehouse Conversion'
    },

    trash: {
      title: 'Removed Warehouse Conversion List'
    },

    insert: {
      title: 'Insert Warehouse Conversion'
    },

    profile: {
      title: 'Warehouse Conversion Profile'
    },

    edit: {
      title: 'Edit Warehouse Conversion'
    },

    logs: {
      title: 'Warehouse Conversion Logs',
    },
  },

  received: {
    title: 'Pull in'
  },

  transferred: {
    title: 'Transferred'
  },

  sended: {
    title: 'Pull out'
  }
}
