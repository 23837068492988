export default {
  title: 'افزونه ها',

  wooreceiver: {
    title: 'اتصال ساز به ووکامرس',

    shopStatus: {
      disActive: 'غیرفعال',
      checkInformation: 'بررسی اطلاعات',
      stopped: 'متوقف شده',
      active: 'فعال',
      receivingInformation: 'در حال تبادل اطلاعات'
    },

    labels: {
      name: 'نام فروشگاه',
      url: 'آدرس فروشگاه',
      key: 'کلید احراز هویت اینترفیس',
      secret: 'رمز احراز هویت اینترفیس',
      receive: 'دریافت',
      userInfo: 'اطلاعات کاربران',
      productInfo: 'اطلاعات محصولات',
      orderInfo: 'اطلاعات سفارشات',
      delete: 'حذف فروشگاه',
      product: 'کالا',
      setProductPending: 'تغییر وضعیت کالا به در انتظار بررسی بعد از تغییر وضعیت موجودی',
      barcodeContainsNumber: 'بارکد فقط عدد می باشد',
      sale: 'فروش',
      draftOrderEnabled: 'پیش فاکتور ایجاد شود',
      inventory: 'انبارداری',
      storeroomsPriority: 'اولویت انبارها برای فروش'
    },

    description: {
      notExist: 'هیچ فروشگاهی ثبت نشده است!'
    },

    notifications: {
      receivingInformation: 'اتصال ساز فروشگاه مورد نظر در حال دریافت اطلاعات می باشد. لطفا منتظر بمانید...',
      noChange: 'هیچ تغییری اعمال نشده است',
      requiredFields: 'مقادیر الزامی وارد نشده است',
      requestSend: 'درخواست با موفقیت ثبت شد',
      serviceNotActive: 'سرویس مورد نظر فعال نمی باشد',

      insert: {
        success: 'اطلاعات فروشگاه جدید با موفقیت ثبت شد',
        error: 'سرویس مورد نظر فعال نمی باشد'
      },

      edit: {
        success: 'اطلاعات فروشگاه با موفقیت ویرایش شد',
        error: 'ویرایش تنظیمات وبسایت با خطا همراه شد'
      },

      editStoreroom: {
        success: 'اطلاعات انبار با موفقیت ویرایش شد',
        error: 'ویرایش اطلاعات انبار با خطا همراه شد'
      },

      delete: {
        success: 'اطلاعات فروشگاه با موفقیت حذف شد',
        error: 'سرویس مورد نظر فعال نمی باشد'
      }
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف فروشگاه',
        body: 'آیا از حذف موقت {name} اطمینان دارید؟'
      }
    },

    profile: {
      title: 'پروفایل سایت',
      dynamicTitle: 'فروشگاه {name}',

      importantData: {
        url: 'آدرس',
        token: 'توکن',
        status: 'وضعیت'
      },

      actions: {
        category: 'لیست دسته بندی ها',
        categoryMapper: 'نگاشت کننده دسته بندی',
        attributes: 'کنترل ویژگی ها',
        products: 'کنترل کالاها',
        inventory: 'کنترل موجودی',
        price: 'کنترل قیمت',
        paymentGateways: 'کنترل روش های دریافت وجه',
        orders: 'کنترل فاکتور ها',
        users: 'کنترل مشتریان',
        setting: 'تنظیمات'
      },

      notifications: {
        attributeErrorNotSolved: 'خطاهای اطلاعات ویژگی ها رفع نشده است'
      }
    },

    insert: {
      title: 'افزودن فروشگاه جدید'
    },

    edit: {
      title: 'ویرایش فروشگاه',
      dynamicTitle: 'ویرایش فروشگاه {name}'
    },

    attributes: {
      list: {
        title: 'کنترل ویژگی ها',

        table: {
          header: {
            rowNumber: 'ردیف',
            name: 'نام ویژگی',
            values: 'مقادیر ویژگی',
            send: 'ارسال'
          }
        },

        confirmations: {
          get: {
            title: 'آیا از دریافت لیست ویژگی ها اطمینان دارید؟'
          },
          sync: {
            title: 'آیا از انتقال لیست ویژگی ها به یوزرال اطمینان دارید؟'
          }
        },

        notifications: {
          handleGet: {
            error: 'اطلاعات تمامی ویژگی ها دریافت شده است'
          },
          handleSync: {
            error: 'اطلاعات تمامی ویژگی ها به یوزرال منتقل شده است'
          },
          receive: {
            success: 'درخواست با موفقیت ارسال شد',
            error: 'ارسال درخواست با خطا همراه شد'
          },
          sync: {
            success: 'درخواست با موفقیت ارسال شد',
            error: 'ارسال درخواست با خطا همراه شد'
          },
          send: {
            success: 'درخواست با موفقیت ارسال شد',
            error: 'ارسال درخواست با خطا همراه شد'
          }
        }
      },

      insert: {
        title: 'افزودن ویژگی جدید'
      },

      edit: {
        title: 'ویرایش ویژگی'
      },

      profile: {
        title: 'ویژگی',
        dynamicTitle: 'ویژگی {name}'
      },

      logs: {
        title: 'لاگ های ویژگی',
        dynamicTitle: 'لاگ های ویژگی {name}',

        labels: {
          values: 'مقادیر ویژگی',
          destinations: 'توضیحات لاگ'
        },

        table: {
          header: {
            rowNumber: 'ردیف',
            description: 'توضیحات',
            date: 'تاریخ'
          }
        }
      }
    },

    categories: {
      title: 'کنترل دسته بندی ها',

      labels: {
        withoutCategory: 'بدون دسته بندی'
      },

      notifications: {
        notCategorySelected: 'هیچ دسته بندی انتخاب نشده است',

        setMainCategories: {
          success: 'دسته بندی های اصلی با موفقیت ثبت شدند',
          error: 'ثبت دسته بندی های اصلی با خطا همراه شد'
        },

        setSetting: {
          success: 'تنظیمات نگاشت کننده دسته بندی ها با موفقیت ثبت شد',
          error: 'ثبت تنظیمات نگاشت کننده دسته بندی ها با خطا همراه شد'
        }
      },

      categoryMapper: {
        title: 'نگاشت کننده دسته بندی ها',

        table: {
          header: {
            rowNumber: 'ردیف',
            name: 'عنوان دسته بندی ووکامرس',
            mapTo: 'عنوان دسته بندی یوزرال'
          }
        }
      }
    },

    products: {
      title: 'کنترل محصولات',

      types: {
        all: 'همه',
        simple: 'کالای ساده',
        variant: 'کالای متغیر',
        service: 'خدمت'
      },

      dataCategory: {
        generalInfo: 'اطلاعات عمومی',
        attributes: 'ویژگی ها',
        inventory: 'مدیریت موجودی',
        variants: 'متغیرها'
      },

      productType: {
        label: 'نوع کالا',
        simple: 'ساده',
        variant: 'متغیر'
      },

      labels: {
        useral: 'یوزرال',
        woocommerce: 'ووکامرس',
        active: 'فعال',
        disActive: 'غیرفعال',
        have: 'دارد',
        notHave: 'ندارد',
        from: 'از',
        to: 'تا',
        withoutPrice: 'بدون قیمت',
        code: 'شناسه',
        barcode: 'بارکد',
        description: 'توضیحات',
        inventory: 'موجودی انبار',
        chooseAttribute: 'انتخاب ویژگی',
        chooseCategory: 'انتخاب دسته بندی',
        withoutCategory: 'بدون دسته بندی',
        add: 'افزودن کالا',
        delete: 'حذف کالا',

        product: {
          name: 'نام کالا',
          productShopName: 'نام نمایشی در فروشگاه',
          category: 'دسته بندی',
          price: 'قیمت کالا',
        },
        service: {
          name: 'نام خدمت',
          category: 'دسته بندی خدمات',
          price: 'قیمت خدمت'
        }
      },

      table: {
        header: {
          delete: 'حذف',
          price: 'قیمت',
          barcode: 'بارکد',
          stock: 'موجودی انبار'
        }
      },

      descriptions: {
        title: 'توضیحات',

        woocommerceDescription: 'اطلاعات محصول به صورت مستقیم در فروشگاه شما در وضعیت در حال بازبینی ثبت میشود.',
        attribute: 'شما می توانید ویژگی مورد نظر خود را انتخاب و به لیست ویژگی های محصول اضافه نمایید. در صورتی که محصول از نوع متغیر باشد، شما میتوانید <b>یکی</b> از ویژگی ها را به عنوان ویژگی متغیر محصول انتخاب نمایید',
        inventory: 'در این صفحه شما می توانید موجودی کل محصول را وارد کنید. در صورتی که تعداد موجودی برابر با صفر باشد، محصول ناموجود در نظر گرفته خواهد شد.',
        noAttributeChooseForVariables: 'هیچ ویژگی به عنوان متغیر انتخاب نشده است'
      },

      confirmations: {
        sync: {
          title: '',
          body: 'آیا از انتقال کالاهای وبسایت به یوزرال اطمینان دارید؟'
        },
        get: {
          title: '',
          body: 'آیا از دریافت لیست کالاها اطمینان دارید؟'
        },
        delete: {
          title: 'تائیدیه',
          body: 'آیا از حذف این کالا اطمینان دارید؟ در صورت تائید، اطلاعات محصول از فروشگاه شما نیز حذف می شود!'
        }
      },

      validators: {
        imageSize: 'حجم فایل انتخاب شده بیش از حد مجاز است',
        fileFormat: 'فرمت فایل انتخاب شده مجاز نیست'
      },

      notifications: {
        requestSend: 'درخواست ارسال شد. لطفا منتظر بمانید...',
        technicalProblem: 'در لیست، کالاهایی با مشکلات فنی وجود دارد، لطفا نسبت به رفع مشکلات اقدام فرمائید',
        useralTransfer: {
          error: 'ابتدا باید همه کالاها به یوزرال منتقل شوند'
        },
        send: {
          success: 'درخواست با موفقیت ارسال شد',
          error: 'ارسال درخواست با خطا همراه شد',
          notTransfer: 'کالای مورد نظر به یوزرال منتقل نشده است'
        },
        choose: {
          dynamicSuccess: 'کالای {name} انتخاب شده است'
        },
        get: {
          success: 'درخواست با موفقیت ثبت شد',
          error: 'ارسال درخواست با خطا همراه شد',
          getAll: 'کالاهای فروشگاه دریافت شده اند'
        },
        publish: {
          success: 'اطلاعات کالاها با موفقیت تائید شد',
          error: 'تائید کردن اطلاعات کالاها با خطا مواجه شد'
        },
        transfer: {
          success: 'درخواست با موفقیت ارسال شد',
          error: 'ارسال درخواست با خطا همراه شد',
          transferAll: 'همه کالاها به یوزرال منتقل شده اند'
        },

        insert: {
          success: 'اطلاعات کالا با موفقیت  در فروشگاه ثبت شد',
          error: 'ثبت اطلاعات کالا با خطا مواجه شد'
        },

        edit: {
          success: 'اطلاعات کالا با موفقیت در فروشگاه بروزرسانی شد',
          error: 'بروزرسانی اطلاعات کالا با خطا مواجه شد'
        },

        delete: {
          success: 'اطلاعات کالا با موفقیت حذف شد',
          error: 'حذف اطلاعات کالا با خطا همراه شد'
        },

        visible: {
          success: 'کالا با موفقیت ظاهر شد',
          error: 'ظاهر شدن کالا با خطا همراه شد'
        },

        invisible: {
          success: 'کالا با موفقیت مخفی شد',
          error: 'مخفی شدن کالا با خطا همراه شد'
        },

        parseError: {
          wrongValues: 'اطلاعات وارد شده صحیح نمی باشد'
        }
      },

      list: {
        title: 'کنترل کالاهای سایت',
        dynamicTitle: 'کنترل کالاهای {name}',

        status: {
          all: 'همه',
          notConnect: 'عدم اتصال',
          draft: 'پیشنویس',
          waiting: 'در انتظار',
          published: 'منتشرشده',
          personal: 'خصوصی'
        },

        inStockTypes: {
          available: 'موجود در انبار',
          notAvailable: 'ناموجود'
        },

        table: {
          header: {
            rowNumber: 'ردیف',
            image: 'عکس',
            code: 'شناسه',
            type: 'نوع',
            creator: 'ایجاد کننده',
            name: 'عنوان',
            shopName: 'نام نمایشی در فروشگاه',
            category: 'دسته بندی',
            technicalProblem: 'مشکل فنی',
            status: 'وضعیت انتشار',
            backOrdered: 'وضعیت پیش فروش',
            inStock: 'وضعیت موجودی',
            manageStock: 'مدیریت موجودی',
            receivedDate: 'تاریخ دریافت',
            transferredDate: 'تاریخ انتقال',
            send: 'ارسال'
          }
        },

        variantTable: {
          header: {
            rowNumber: 'ردیف',
            receivedId: 'شناسه متغیر',
            variant: 'متغیر',
            stock: 'موجودی',
            barcode: 'بارکد',
            price: 'قیمت',
            transferStatus: 'وضعیت انتقال',
            published: 'وضعیت انتشار',
            inStock: 'وضعیت موجودی'
          }
        }
      },

      profile: {
        title: 'پروفایل کالا',

        importantData: {
          price: 'قیمت',
          salePrice: 'قیمت حراج',
          type: 'نوع'
        },

        actions: {
          attributes: 'ویژگی های محصول',
          variant: 'متغیر های محصول',
          logs: 'لاگ های محصول'
        }
      },

      insert: {
        title: 'افزودن کالای جدید',

        notifications: {
          insertSoon: 'تا لحظاتی دیگر اطلاعات کالا در ووکنترلر ذخیره می شود'
        }
      },

      edit: {
        title: 'ویرایش کالا',

        labels: {
          salePrice: 'قیمت حراج',
          manageStock: 'مدیریت موجودی',
          backOrdered: 'پیش فروش',
          attributeInsert: 'ایجاد متغیر جدید'
        },

        description: {
          editVariant: 'شما می توانید از طریق این صفحه، اطلاعات مربوط به هر متغیر را در فروشگاه خود ویرایش کنید.',
          error: 'عملیات با خطا همراه شد',
          noLog: 'هیچ لاگی برای این محصول وجود ندارد'
        },

        notifications: {
          variantExist: 'متغیری با این ویژگی وجود دارد',
          salePrice: 'قیمت حراج نمی تواند از قیمت اصلی بیشتر باشد',
          dynamicSalePrice: 'قیمت حراج متغیر با بارکد {barcode} نمی تواند از قیمت اصلی بیشتر باشد'
        }
      },

      logs: {
        title: 'لاگ های کالا',
        dynamicTitle: 'لاگ های {name}',

        descriptions: 'توضیحات لاگ',

        table: {
          header: {
            rowNumber: 'ردیف',
            description: 'توضیحات',
            date: 'تاریخ'
          }
        }
      },

      attributes: {
        title: 'ویژگی های محصول',
        dynamicTitle: 'ویژگی های {name}',

        table: {
          header: {
            rowNumber: 'ردیف',
            key: 'عنوان ویژگی',
            value: 'مقدار ویژگی'
          }
        }
      },

      variant: {
        title: 'متغیرهای محصول',
        dynamicTitle: 'متغیر های {name}',

        table: {
          header: {
            logs: 'لاگ ها',
            transferredDate: 'تاریخ انتقال',
            receivedDate: 'تاریخ دریافت',
            creator: 'ایجاد کننده',
            salePrice: 'قیمت حراج',
            price: 'قیمت',
            barcode: 'بارکد',
            stock: 'موجودی'
          }
        },

        edit: {
          labels: {
            barcode: 'بارکد',
            price: 'قیمت',
            salePrice: 'قیمت حراج',
            stock: 'موجودی انبار',
            manageStock: 'مدیریت موجودی',
            backOrdered: 'پیش فروش',
            delete: 'حذف کالا'
          },

          confirmations: {
            delete: {
              title: 'تائیدیه',
              body: 'آیا از حذف این متغیر اطمینان دارید؟ در صورت تائید، اطلاعات محصول از فروشگاه شما نیز حذف می شود!'
            }
          }
        },

        logs: {
          dynamicTitle: 'لاگ های متغیر با بارکد {barcode}'
        },

        notifications: {
          delete: {
            success: 'اطلاعات متغیر با موفقیت حذف شد',
            error: 'حذف اطلاعات متغیر با خطا همراه شد'
          }
        }
      }
    },

    inventory: {
      title: 'کنترل موجودی',
      dynamicTitle: 'کنترل موجودی {name}',

      labels: {
        notAvailable: 'ناموجود'
      },

      table: {
        header: {
          rowNumber: 'ردیف',
          image: 'عکس',
          code: 'شناسه',
          barcode: 'بارکد',
          name: 'عنوان',
          variant: 'متغیر',
          woocommerceStock: 'موجودی سایت',
          useralStock: 'یوزرال',
          updateDate: 'تاریخ بروزرسانی سایت',
          sync: 'ارسال'
        }
      },

      confirmations: {
        syncUseral: {
          title: 'پیغام',
          body: 'آیا از انتقال موجودی کالاها به یوزرال اطمینان دارید؟'
        },

        syncWoocommerce: {
          title: 'پیغام',
          body: 'آیا از ارسال موجودی کالاها به ووکامرس اطمینان دارید؟'
        },

        receive: {
          title: 'پیغام',
          body: 'آیا از دریافت موجودی کالاها از ووکامرس اطمینان دارید؟'
        }
      },

      notifications: {
        transferAll: 'ابتدا باید همه کالاها به یوزرال منتقل شوند',
        sync: {
          success: 'موجودی این محصول در سایت بروز شد',
          error: 'بروزرسانی موجودی محصول در سایت با خطا همراه شد'
        },

        requestSend: {
          success: 'درخواست ارسال شد',
          error: 'ارسال درخواست با خطا همراه شد'
        }
      }
    },

    prices: {
      title: 'کنترل قیمت',
      dynamicTitle: 'کنترل قیمت {name}',

      table: {
        header: {
          rowNumber: 'ردیف',
          image: 'عکس',
          code: 'شناسه',
          barcode: 'بارکد',
          name: 'عنوان',
          variant: 'متغیر',
          woocommercePrice: 'قیمت سایت',
          useralPrice: 'قیمت یوزرال',
          woocommerceSalePrice: 'قیمت حراج سایت',
          useralSalePrice: 'قیمت حراج یوزرال',
          updateDate: 'تاریخ بروزرسانی سایت',
          update: 'بروزرسانی',
          sync: 'ارسال'
        }
      },

      confirmations: {
        syncUseral: {
          title: 'پیغام',
          body: 'آیا از انتقال قیمت کالاها به یوزرال اطمینان دارید؟'
        },

        syncWoocommerce: {
          title: 'پیغام',
          body: 'آیا از ارسال قیمت کالاها به ووکامرس اطمینان دارید؟'
        },

        receive: {
          title: 'پیغام',
          body: 'آیا از دریافت قیمت کالاها از ووکامرس اطمینان دارید؟'
        }
      },

      notifications: {
        transferToUseral: 'ابتدا باید همه کالاها به یوزرال منتقل شوند',
        notTransfer: 'محصول به یوزرال منتقل نشده است',
        inventoryCorrect: 'موجودی این محصول در سایت صحیح است',

        requestSend: {
          success: 'درخواست ارسال شد',
          error: 'ارسال درخواست با خطا همراه شد'
        },

        edit: {
          success: 'موجودی این محصول در سایت بروز شد',
          error: 'بروزرسانی موجودی محصول در سایت با خطا همراه شد'
        }
      }
    },

    paymentGateways: {
      title: 'کنترل روش های دریافت وجه',
      dynamicTitle: 'کنترل روش های دریافت وجه {name}',

      statusTypes: {
        all: 'همه',
        notConnect: 'عدم اتصال',
        active: 'فعال',
        disActive: 'غیرفعال'
      },

      table: {
        header: {
          rowNumber: 'ردیف',
          title: 'عنوان درگاه',
          status: 'وضعیت',
          receivedDate: 'تاریخ دریافت',
          transferredDate: 'تاریخ انتقال',
          send: 'ارسال'
        }
      },

      confirmations: {
        get: {
          title: '',
          body: 'آیا از دریافت لیست کالاها اطمینان دارید؟'
        }
      },

      notifications: {
        notTransfer: 'روش دریافت وجه مورد نظر به یوزرال منتقل نشده است',
        receivedProducts: 'کالاهای فروشگاه دریافت شده اند',

        requestSend: {
          success: 'درخواست با موفقیت ارسال شد',
          error: 'ارسال درخواست با خطا همراه شد'
        },

        requestSave: {
          success: 'درخواست با موفقیت ثبت شد'
        }
      },

      sync: {
        title: '',
        dynamicTitle: 'انتقال درگاه «{name}» به یوزرال',

        types: {
          internet: 'درگاه اینترنتی',
          pos: 'کارتخوان'
        },

        labels: {
          bank: 'بانک تسویه',
          type: 'نوع درگاه',
          endTime: 'ساعت پایان روز کاری درگاه',
          descriptions: 'توضیحات:'
        },

        notifications: {
          parseError: {
            bank: 'بانک تسویه انتخاب نشده است',
            type: 'نوع درگاه انتخاب نشده است',
            endTime: 'ساعت پایان روز کاری درگاه به طور صحیح وارد نشده است'
          }
        }
      }
    },

    orders: {
      title: 'کنترل فاکتورها',

      invoiceProcesses: {
        notConnect: 'عدم اتصال',
        paymentWaiting: 'در انتظار پرداخت',
        doing: 'در حال انجام',
        reviewWaiting: 'در انتظار بررسی',
        completed: 'تکمیل شده',
        cancel: 'لغو شده',
        rejected: 'مرجوع شده',
        unSuccess: 'ناموفق',
        deleted: 'حذف شده'
      },

      list: {
        title: 'لیست سفارشات فروشگاه',
        dynamicTitle: 'لیست سفارشات فروشگاه {name}',

        technicalProblemTypes: {
          all: 'همه',
          have: 'دارد',
          notHave: 'ندارد'
        },

        statusTypes: {
          all: 'همه',
          preInvoice: 'پیش فاکتور خرید',
          invoice: 'فاکتور خرید',
          storeReceipt: 'رسید انبار'
        },

        labels: {
          unknown: 'مشخص نیست'
        },

        table: {
          header: {
            invoiceNumber: 'شماره فاکتور',
            receivedId: 'شناسه سفارش',
            status: 'وضعیت',
            user: 'طرف حساب',
            date: 'تاریخ',
            finalPrice: 'مبلغ نهایی',
            totalDiscount: 'تخفیف کل',
            technicalProblem: 'مشکل فنی',
            send: 'ارسال'
          }
        },

        notifications: {
          notTransfer: 'فاکتور مورد نظر به یوزرال منتقل نشده است',
          activeRefundInvoiceExists: 'برای این فاکتور، فاکتور مرجوع {invoices} ثبت شده اند. ابتدا وضعیت این فاکتورها را به پیش فاکتور تغییر دهید | برای این فاکتور، فاکتورهای مرجوع {invoices} ثبت شده اند. ابتدا وضعیت این فاکتورها را به پیش فاکتور تغییر دهید',

          requestSend: {
            success: 'درخواست با موفقیت ارسال شد',
            error: 'ارسال درخواست با خطا همراه شد'
          },

          delete: {
            success: 'فاکتور با موفقیت حذف شد',
            error: 'فاکتور قابل حذف نیست'
          },
        },

        confirmations: {
          delete: {
            title: 'تائیدیه حذف فاکتور فروش',
            body: 'در صورت حذف فاکتور فروش تمامی اسناد و حواله های انبار مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
          }
        }
      },

      order: {
        title: 'فاکتور سفارش کالا',
        dynamicTitle: 'فاکتور سفارش با شماره {number}',

        labels: {
          user: 'طرف حساب',
          id: 'شناسه فاکتور',
          date: 'تاریخ ثبت',
          status: 'وضعیت'
        },

        placeholders: {
          id: 'اتوماتیک'
        },

        table: {
          header: {
            rowNumber: 'ردیف',
            code: 'بارکد',
            name: 'کالا',
            variant: 'متغیر',
            store: 'انبار',
            count: 'تعداد',
            unitPrice: 'مبلغ واحد',
            totalPrice: 'مبلغ کل',
            discount: 'تخفیف',
            finalPrice: 'مبلغ نهایی'
          }
        }
      },

      logs: {
        title: 'لاگ های فاکتور سفارش کالا',
        dynamicTitle: 'لاگ های فاکتور سفارش با شماره {number}',

        descriptions: 'توضیحات لاگ',

        table: {
          header: {
            rowNumber: 'ردیف',
            description: 'توضیحات',
            date: 'تاریخ'
          }
        }
      }
    },

    users: {
      title: 'کنترل مشتریان',
      dynamicTitle: 'کنترل مشتریان {name}',
      invisibleDynamicTitle: 'کنترل مشتریان مخفی {name}',

      technicalProblemTypes: {
        all: 'همه',
        have: 'دارد',
        notHave: 'ندارد'
      },

      labels: {
        useral: 'یوزرال',
        unknown: 'نامشخص'
      },

      table: {
        header: {
          rowNumber: 'ردیف',
          avatar: 'آواتار',
          receivedId: 'شناسه مشتری',
          fullName: 'نام و نام خانوادگی',
          company: 'شرکت',
          phoneNumber: 'تلفن همراه',
          technicalProblem: 'مشکل فنی',
          receivedDate: 'تاریخ دریافت',
          transferredDate: 'تاریخ انتقال',
          send: 'ارسال'
        }
      },

      confirmations: {
        sync: {
          title: '',
          body: 'آیا از انتقال اطلاعات مشتری های وبسایت به یوزرال اطمینان دارید؟'
        },

        technicalError: {
          title: 'خطا',
          body: 'اطلاعات برخی از مشتری ها دارای مشکل فنی می باشد، لطفا نسبت به رفع مشکلات اقدام فرمائید'
        },

        getUsers: {
          title: '',
          body: 'آیا از دریافت لیست مشتریان اطمینان دارید؟'
        }
      },

      notifications: {
        syncUsers: 'اطلاعات همه مشتریان از سایت دریافت شده است',
        notTransfer: 'مشتری مورد نظر به یوزرال منتقل نشده است',
        transferToUseral: 'اطلاعات همه مشتریان به یوزرال منتقل شده است',

        requestSend: {
          success: 'درخواست با موفقیت ارسال شد',
          error: 'ارسال درخواست با خطا همراه شد'
        },

        visible: {
          success: 'کاربر با موفقیت ظاهر شد',
          error: 'ظاهر شدن کاربر با خطا همراه شد'
        },

        invisible: {
          success: 'کاربر با موفقیت مخفی شد',
          error: 'مخفی شدن کاربر با خطا همراه شد'
        },

        requestSave: {
          success: 'درخواست با موفقیت ثبت شد'
        }
      },

      logs: {
        title: 'لاگ های کاربر',
        dynamicTitle: 'لاگ های {name}',
        description: 'توضیحات لاگ',

        table: {
          header: {
            rowNumber: 'ردیف',
            description: 'توضیحات',
            date: 'تاریخ'
          }
        }
      },

      edit: {
        title: '',
        dynamicTitle: 'ویرایش {name}',

        dataCategory: {
          generalInfo: 'اطلاعات عمومی'
        },

        labels: {
          name: 'نام',
          family: 'نام خانوادگی',
          company: 'شرکت',
          phoneNumber: 'شماره همراه',
          email: 'ایمیل',
          delete: 'حذف مشتری',
          customerLogs: 'لاگ های مشتری'
        },

        descriptions: {
          noLog: 'هیچ لاگی برای این شخص وجود ندارد'
        },

        confirmations: {
          delete: {
            title: 'تائیدیه',
            body: 'آیا از حذف اطلاعات این مشتری اطمینان دارید؟ در صورت تائید، اطلاعات مشتری از وبسایت شما نیز حذف می شود!'
          }
        },

        notifications: {
          wrongInputValues: 'اطلاعات وارد شده صحیح نمی باشد',

          requestSend: {
            success: 'درخواست ارسال شد',
            wait: 'درخواست ارسال شد. لطفا منتظر بمانید...'
          },

          edit: {
            success: 'اطلاعات شخص با موفقیت بروزرسانی شد',
            error: 'بروزرسانی اطلاعات شخص با خطا مواجه شد'
          },

          delete: {
            error: 'حذف اطلاعات شخص با خطا همراه شد'
          }
        }
      }
    },

    setting: {
      title: 'تنظیمات اتصال ساز'
    }
  }
}
